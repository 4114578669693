/* eslint-disable */

import {
    Flex,
    Box,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Select,
    Button,
    Tooltip,
    Icon,
    Spinner,
} from "@chakra-ui/react";
import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { isSameDay } from "date-fns";
import * as XLSX from "xlsx";
import Card from "components/card/Card";
import { FaDownload } from "react-icons/fa";

const columnHelper = createColumnHelper();

export default function CheckTable() {
    const [tableData, setTableData] = React.useState([]); // Holds table data
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [totalPages, setTotalPages] = React.useState(1);
    const [perPage, setPerPage] = React.useState(10); // Number of entries per page
    const [currentPage, setCurrentPage] = React.useState(1);
    const [availableDates, setAvailableDates] = React.useState([]);
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [loading, setLoading] = React.useState(false); // Loading state
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    // Fetch Available Dates on Load
    React.useEffect(() => {
        const fetchAvailableDates = async () => {
            try {
                const response = await fetch("https://juicd.io/api/asx-available-dates", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    const parsedDates = data.dates.map((date) => new Date(date));
                    setAvailableDates(parsedDates);

                    // Automatically set the latest available date and fetch table data
                    if (parsedDates.length > 0) {
                        setSelectedDate(parsedDates[0]); // Latest date
                        fetchTableData(parsedDates[0], 1, perPage); // Fetch data for the latest date
                    }
                } else {
                    console.error("Failed to fetch available dates");
                }
            } catch (error) {
                console.error("Error fetching available dates:", error);
            }
        };
        fetchAvailableDates();
    }, []);

    // Fetch Table Data Function
    const fetchTableData = async (date, page, limit) => {
        try {
            setLoading(true); // Start loading
            const formattedDate = date.toISOString().slice(0, 10); // Format as YYYY-MM-DD
            const response = await fetch(
                `https://juicd.io/api/shorted-stocks?data_date=${formattedDate}&page=${page}&per_page=${limit}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            if (response.ok) {
                const data = await response.json();
                setTableData(data.data || []);
                setTotalRecords(data.total_records || 0);
                setTotalPages(data.total_pages || 1);
            } else {
                console.error("Failed to fetch table data");
            }
        } catch (error) {
            console.error("Error fetching table data:", error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    // Handle Date Selection
    const handleDateChange = (date) => {
        setSelectedDate(date); // Update selected date
        fetchTableData(date, 1, perPage); // Fetch data for the new date
    };

    // Handle Page Change
    const handlePageChange = (page) => {
        setCurrentPage(page);
        if (selectedDate) {
            fetchTableData(selectedDate, page, perPage); // Fetch data for the current page
        }
    };

    // Handle Change in Entries Per Page
    const handlePerPageChange = (event) => {
        const newPerPage = Number(event.target.value);
        setPerPage(newPerPage); // Update the number of entries per page
        setCurrentPage(1); // Reset to the first page
        if (selectedDate) {
            fetchTableData(selectedDate, 1, newPerPage); // Fetch data for the first page with new limit
        }
    };

    // Handle Export to Excel
    const handleExport = () => {
        if (tableData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(tableData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "ASX Top Short Sells");
            XLSX.writeFile(wb, `ASX_Short_Sell_Report_${new Date().toISOString().slice(0, 10)}.xlsx`);
        } else {
            console.error("No data available to export.");
        }
    };

    // Define Table Columns
    const columns = [
        columnHelper.accessor("asx_code", {
            header: "ASX Code",
            cell: (info) => <Text color={textColor} fontSize="xs" fontWeight="normal">{info.getValue() || "N/A"}</Text>,
        }),
        columnHelper.accessor("company_name", {
            header: "Company Name",
            cell: (info) => <Text color={textColor} fontSize="xs" fontWeight="normal">{info.getValue() || "N/A"}</Text>,
        }),
        columnHelper.accessor("industry", {
            header: "Industry",
            cell: (info) => <Text color={textColor} fontSize="xs" fontWeight="normal">{info.getValue() || "N/A"}</Text>,
        }),
        // columnHelper.accessor("gross_short_sold_volume", {
        //     header: "Gross Short Volume",
        //     cell: (info) => <Text color={textColor} fontSize="xs" fontWeight="normal">{info.getValue() || "N/A"}</Text>,
        // }),
        // columnHelper.accessor("issued_capital", {
        //     header: "Issued Capital",
        //     cell: (info) => <Text color={textColor} fontSize="xs" fontWeight="normal">{info.getValue() || "N/A"}</Text>,
        // }),
        columnHelper.accessor("percent_issued_capital", {
            header: "Short Sale %",
            cell: (info) => {
                const value = info.getValue();
                return <Text color={textColor} fontSize="xs" fontWeight="normal">{value ? `${value}%` : "N/A"}</Text>;
            },
        }),
        columnHelper.accessor("data_date", {
            header: "Date",
            cell: (info) => <Text color={textColor} fontSize="xs" fontWeight="normal">{info.getValue() || "N/A"}</Text>,
        }),
    ];

    const table = useReactTable({
        data: tableData,
        columns,
        state: { sorting: [] },
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Flex px="30px" mb="8px" justifyContent="space-between" align="center">
                <Text color={textColor} fontSize="22px" fontWeight="700">ASX: Top Shorted Stocks</Text>
                <Flex alignItems="center" gap="10px">
                    <ReactDatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        highlightDates={availableDates}
                        maxDate={new Date()}
                        customInput={
                            <Button size="sm" variant="outline" borderColor={borderColor}>
                                {selectedDate ? selectedDate.toISOString().slice(0, 10) : "Select Date"}
                            </Button>
                        }
                    />
                        <Text fontSize="sm">Show:</Text>
                    <Select
                        w="100px"
                        size="sm"
                        value={perPage}
                        onChange={handlePerPageChange}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </Select>
                    <Tooltip label="Export to Excel" fontSize="md" placement="top">
                        <Button
                            onClick={handleExport}
                            leftIcon={<Icon as={FaDownload} />}
                            colorScheme="teal"
                            size="sm"
                        >
                            Export
                        </Button>
                    </Tooltip>
                </Flex>
            </Flex>
            <Box>
                {loading ? (
                    <Flex justify="center" align="center" py="20">
                        <Spinner size="xl" color="teal.500" />
                    </Flex>
                ) : (
                    <>
                        <Table variant="striped" color="gray.700">
                            <Thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <Tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => (
                                            <Th key={header.id} textAlign="center" align="center">
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </Th>
                                        ))}
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody>
                                {table.getRowModel().rows.map((row) => (
                                    <Tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <Td
                                                key={cell.id}
                                                textAlign="center"
                                                align="center"
                                                py={1}
                                                px={2}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </Td>
                                        ))}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                        <Flex justify="space-between" align="center" px="25px" py="10px">
                            <Text>Page {currentPage} of {totalPages}</Text>
                            <Flex gap="10px">
                                <Button
                                    size="sm"
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </Button>
                                <Button
                                    size="sm"
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </Button>
                            </Flex>
                        </Flex>
                    </>
                )}
            </Box>
        </Card>
    );
}
