import React, { useEffect, useState } from "react";
import {
    Box,
    SimpleGrid,
    Flex,
    Spinner,
    useColorModeValue,
} from "@chakra-ui/react";
import CheckTable from "views/admin/default/components/CheckTable";
import CitiPDFStockTable from "views/admin/default/components/CitiPDFStockTable";

export default function SymbolDetails() {
    const [loading, setLoading] = useState(true); // Tracks loading state
    const [tableData, setTableData] = useState([]); // Holds fetched data
    const [columnsData, setColumnsData] = useState([]); // Column definitions

    const brandColor = useColorModeValue("brand.500", "white"); // Color mode for styling

    // Function to fetch data for the page
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await fetch("https://juicd.io/api/etf-report", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`, // Example authorization
                },
            });
            const result = await response.json();
            setTableData(result.data || []); // Populate table data
            setColumnsData(generateColumns(result.data || [])); // Generate columns
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    // Dynamically generate columns for the table
    const generateColumns = (data) => {
        const columnMappings = {
            etf_code: "ETF Code",
            country: "Country",
            region: "Region",
            market: "Market",
        };
        return Object.keys(data[0] || {}).map((key) => ({
            Header: columnMappings[key] || key,
            accessor: key,
        }));
    };

    // Fetch data on component mount
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Box pt="80px">
            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mb="20px">
                <Flex align="center" justify="center">
                    {/* Reusable loading spinner */}
                    {loading ? (
                        <Spinner color={brandColor} size="lg" />
                    ) : (
                        <CheckTable columnsData={columnsData} tableData={tableData} />
                    )}
                </Flex>
            </SimpleGrid>

            {/* Add more sections as needed */}
            <SimpleGrid columns={{ base: 1, md: 1 }} gap="20px" mb="20px">
                <CitiPDFStockTable />
            </SimpleGrid>
        </Box>
    );
}
