/* eslint-disable */

import {
    Flex,
    Box,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Select,
    Button,
    Tooltip,
    Icon,
    Spinner,
} from "@chakra-ui/react";
import React from "react";
import { flexRender, createColumnHelper, useReactTable, getCoreRowModel } from "@tanstack/react-table";
import * as XLSX from "xlsx";
import ReactDatePicker from "react-datepicker"; // NEW: Import React Datepicker
import "react-datepicker/dist/react-datepicker.css"; // NEW: Import Datepicker CSS
import Card from "components/card/Card";
import { FaDownload } from "react-icons/fa";

const columnHelper = createColumnHelper();

export default function CitiPDFInternationalIndexTable() {
    const [tableData, setTableData] = React.useState([]);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [totalPages, setTotalPages] = React.useState(1);
    const [perPage, setPerPage] = React.useState(5);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [loading, setLoading] = React.useState(false);

    // NEW: State to manage the selected date for fetching data
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [availableDates, setAvailableDates] = React.useState([]);
    // States to hold the dates for the headers
    const [prevCostDate, setPrevCostDate] = React.useState("");
    const [latestCostDate, setLatestCostDate] = React.useState("");

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    // Utility function to format dates in "YYYY-MM-DD" format
    const formatDate = (dateString) => {
        if (!dateString) return "N/A"; // Handle empty or invalid dates
        const date = new Date(dateString); // Convert string to Date object
        return date.toISOString().split("T")[0]; // Format as "YYYY-MM-DD"
    };

    // Fetch Available Dates on Load
    React.useEffect(() => {
        const fetchAvailableDates = async () => {
            try {
                const response = await fetch("https://juicd.io/api/citi-InternationaIndex-available-dates", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    const parsedDates = data.dates.map((date) => new Date(date));
                    setAvailableDates(parsedDates);

                    // Automatically set the latest available date and fetch table data
                    if (parsedDates.length > 0) {
                        setSelectedDate(parsedDates[0]); // Latest date
                        fetchTableData(parsedDates[0], 1, perPage); // Fetch data for the latest date
                    }
                } else {
                    console.error("Failed to fetch available dates");
                }
            } catch (error) {
                console.error("Error fetching available dates:", error);
            }
        };
        fetchAvailableDates();
    }, []);

    // Fetch Table Data
    const fetchTableData = async (page, limit, date = null) => {
        try {
            setLoading(true);
            const formattedDate = date ? date.toISOString().slice(0, 10): null; // Format as YYYY-MM-DD
            const dateParam = formattedDate ? `&date=${formattedDate}` : ""; // Add date parameter if provided
            const response = await fetch(
                `https://juicd.io/api/citi-pdf-InternationalIndex?page=${page}&per_page=${limit}${dateParam}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            if (response.ok) {
                const data = await response.json();

                // Extract the dates for the headers
                if (data.latest_date && data.second_latest_date) {
                    setLatestCostDate(formatDate(data.latest_date)); // Set "Latest Cost" date
                    setPrevCostDate(formatDate(data.second_latest_date)); // Set "Prev Cost" date
                }

                setTableData(data.data || []);
                setTotalRecords(data.total_records || 0);
                setTotalPages(data.total_pages || 1);
            } else {
                console.error("Failed to fetch data");
            }
        } catch (error) {
            console.error("Error fetching table data:", error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        fetchTableData(currentPage, perPage, selectedDate); // MODIFIED: Include `selectedDate` in the fetch call
    }, [currentPage, perPage, selectedDate]); // MODIFIED: Add `selectedDate` as a dependency

    // Handle Date Selection
    const handleDateChange = (date) => {
        setSelectedDate(date); // Update selected date
        fetchTableData(date, 1, perPage); // Fetch data for the new date
    };
    // Handle Change in Entries Per Page
    const handlePerPageChange = (event) => {
        const newPerPage = Number(event.target.value);
        setPerPage(newPerPage);
        setCurrentPage(1);
        fetchTableData(1, newPerPage, selectedDate); // MODIFIED: Pass `selectedDate` to fetch data
    };

    // Handle Export to Excel
    const handleExport = () => {
        if (tableData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(tableData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Citi PDF Int Index");
            XLSX.writeFile(wb, `Citi_PDF_Int_Index_Report_${new Date().toISOString().slice(0, 10)}.xlsx`);
        } else {
            console.error("No data available to export.");
        }
    };

    // Define Table Columns
    const columns = [
        columnHelper.accessor("CitiFirst_MINI_Code", {
            header: "Code",
            cell: (info) => (
                <Text color={textColor} fontSize="xs" fontWeight="normal">
                    {info.getValue() || "N/A"}
                </Text>
            ),
        }),
        columnHelper.accessor("Underlying", {
            header: "Underlying",
            cell: (info) => (
                <Text color={textColor} fontSize="xs" fontWeight="normal">
                    {info.getValue() || "N/A"}
                </Text>
            ),
        }),
        columnHelper.accessor("Industry", {
            header: "Industry",
            cell: (info) => (
                <Text color={textColor} fontSize="xs" fontWeight="normal">
                    {info.getValue() || "N/A"}
                </Text>
            ),
        }),
        columnHelper.accessor("Type", {
            header: "Type",
            cell: (info) => (
                <Text color={textColor} fontSize="xs" fontWeight="normal">
                    {info.getValue() || "N/A"}
                </Text>
            ),
        }),
        columnHelper.accessor("Previous Cost", {
            header: `Prev Cost (${prevCostDate || "N/A"})`, // Show "Prev Cost" with date in the header
            cell: (info) => (
                <Text color={textColor} fontSize="xs" fontWeight="normal">
                    {info.getValue() || "N/A"}
                </Text>
            ),
        }),
        columnHelper.accessor("Latest Cost", {
            header: `Latest Cost (${latestCostDate || "N/A"})`, // Show "Latest Cost" with date in the header
            cell: (info) => (
                <Text color={textColor} fontSize="xs" fontWeight="normal">
                    {info.getValue() || "N/A"}
                </Text>
            ),
        }),
        columnHelper.accessor("Percent Change", {
            header: "% Change",
            cell: (info) => (
                <Text color={textColor} fontSize="xs" fontWeight="normal">
                    {info.getValue() !== null ? `${info.getValue()}` : "N/A"}
                </Text>
            ),
        }),
    ];

    const table = useReactTable({
        data: tableData,
        columns,
        state: { sorting: [] },
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Flex px="30px" mb="8px" justifyContent="space-between" align="center">
                <Text color={textColor} fontSize="22px" fontWeight="700">
                    International
                </Text>
                <Flex alignItems="center" gap="10px">
                    {/* NEW: Add the DatePicker for date selection */}
                    <ReactDatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        highlightDates={availableDates}
                        maxDate={selectedDate}
                        placeholderText="Select Date"
                        dateFormat="yyyy-MM-dd"
                        customInput={
                            <Button size="sm" variant="outline" borderColor={borderColor}>
                                {selectedDate ? selectedDate.toISOString().slice(0, 10) : "Select Date"}
                            </Button>
                        }
                    />
                    <Text fontSize="sm">Show:</Text>
                    <Select w="100px" size="sm" value={perPage} onChange={handlePerPageChange}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </Select>
                    <Tooltip label="Export to Excel" fontSize="md" placement="top">
                        <Button
                            onClick={handleExport}
                            leftIcon={<Icon as={FaDownload} />}
                            colorScheme="teal"
                            size="sm"
                        >
                            Export
                        </Button>
                    </Tooltip>
                </Flex>
            </Flex>
            <Box>
                {loading ? (
                    <Flex justify="center" align="center" py="20">
                        <Spinner size="xl" color="teal.500" />
                    </Flex>
                ) : (
                    <>
                        <Table variant="striped" color="gray.700">
                            <Thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <Tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => (
                                            <Th key={header.id} textAlign="center" align="center">
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </Th>
                                        ))}
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody>
                                {table.getRowModel().rows.map((row) => (
                                    <Tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <Td
                                                key={cell.id}
                                                textAlign="center"
                                                align="center"
                                                py={1}
                                                px={2}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </Td>
                                        ))}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                        <Flex justify="space-between" align="center" px="25px" py="10px">
                            <Text>Page {currentPage} of {totalPages}</Text>
                            <Flex gap="10px">
                                <Button
                                    size="sm"
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </Button>
                                <Button
                                    size="sm"
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </Button>
                            </Flex>
                        </Flex>
                    </>
                )}
            </Box>
        </Card>
    );
}
