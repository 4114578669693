// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  Box,
  Spinner,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom'; // Import for navigation
import axios from "axios"; // Import axios for API calls
import debounce from "lodash.debounce"; // Debounce to optimize API calls
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React, { useState, useEffect,useRef } from 'react';
// Assets
import navImage from 'assets/img/layout/Navbar.png';
import { MdNotificationsNone, MdInfoOutline } from 'react-icons/md';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { FaEthereum } from 'react-icons/fa';
import routes from 'routes';

export default function HeaderLinks(props) {
  const { secondary } = props;

  // Add missing hooks and variables
  const [searchQuery, setSearchQuery] = useState(""); // User's input
  const [searchResults, setSearchResults] = useState([]); // Results from API
  const [currentPage, setCurrentPage] = useState(1); // Initialize currentPage state
  const [filteredResults, setFilteredResults] = useState([]); // Results after applying filters
  const [selectedFilter, setSelectedFilter] = useState("all"); // Selected filter
  const [isLoading, setIsLoading] = useState(false); // Loader for API calls
  const { colorMode, toggleColorMode } = useColorMode(); // Use color mode hooks
  const [hasMore, setHasMore] = useState(true); // Indicates if there are more results to load
  const dropdownRef = useRef(null); // Ref to detect scroll events in the dropdown
  const menuBg = useColorModeValue('white', 'navy.800'); // Menu background
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const shadow = useColorModeValue(
      '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
      '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  ); // Shadow for menus
  const dropdownBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const navbarIcon = useColorModeValue('gray.400', 'white');
  const navigate = useNavigate();

  // Debounced API call
  const fetchSearchResults = debounce(async (query, filter = "all", page = 1) => {
    if (!query) {
      setSearchResults([]);
      setFilteredResults([]);
      setIsLoading(false);
      return;
    }
    try {
      setIsLoading(true);
      const response = await axios.get(
          `https://juicd.io/api/search-symbols?query=${query}&filter=${filter}&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
      );
      // // Log debug information
      // console.log({
      //   currentPage: page,
      //   totalPages: response?.data?.total_pages,
      //   hasMore: page < response?.data?.total_pages,
      //   isLoading: false,
      //   resultsFetched: response?.data?.results?.length,
      // });
      const results = response.data.results || [];
      setSearchResults(results);
      setFilteredResults(results);
      setCurrentPage(page); // Reset current page
      setHasMore(page < response.data.total_pages); // Use total_pages to determine if more pages exist
    } catch (error) {
      console.error("Error fetching search results:", error.response || error.message);
    } finally {
      setIsLoading(false);
    }
  }, 300);

  const fetchMoreResults = async () => {
    if (!hasMore || isLoading) return; // Prevent fetching if already loading or no more results

    try {
      setIsLoading(true);
      const nextPage = currentPage + 1;
      const response = await axios.get(
          `https://juicd.io/api/search-symbols?query=${searchQuery}&filter=${selectedFilter}&page=${nextPage}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
      );
      // Log debug information
      // console.log('API Response:', response.data);
      // console.log({
      //   currentPage: nextPage,
      //   totalPages: response?.data?.total_pages,
      //   hasMore: nextPage < response?.data?.total_pages,
      //   isLoading: true,
      //   resultsFetched: response?.data?.results?.length,
      // });
      const results = response.data.results || [];
      setSearchResults((prev) => [...prev, ...results]);
      setFilteredResults((prev) => [...prev, ...results]);
      setCurrentPage(nextPage); // Increment page number
      setHasMore(nextPage < response.data.total_pages && response.data.results.length > 0);// Use total_pages to determine if more pages exist
    } catch (error) {
      console.error("Error fetching more results:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const dropdownElement = dropdownRef.current;

    const handleScroll = (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      if (scrollHeight - scrollTop <=  clientHeight + 50 && hasMore && !isLoading) {
        // console.log('Fetch More condition satisfied!')
        fetchMoreResults(); // Fetch more results when the user reaches the bottom
      }
      // console.log('Dropdown scrolled:', { scrollTop, scrollHeight, clientHeight });
    };

    if (dropdownElement) {
      dropdownElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (dropdownElement) {
        dropdownElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasMore, isLoading, searchQuery, selectedFilter]); // Include dependencies


  // Effect to control API call triggers
  useEffect(() => {
    if (searchQuery) {
      fetchSearchResults(searchQuery, selectedFilter, 1);
      setCurrentPage(1); // Reset to the first page
      setHasMore(true); // Reset the `hasMore` flag
    } else {
      setSearchResults([]);
      setFilteredResults([]);
      setHasMore(false); // No results to fetch
    }
  }, [searchQuery, selectedFilter]);



  // Handle input change
  const handleInputChange = (query) => {
    setSearchQuery(query); // Only update searchQuery; API call is managed by useEffect
  };

  // Handle filter selection
  const handleFilterChange = (filter) => {
    setSelectedFilter(filter); // Only update selectedFilter; API call is managed by useEffect
  };

  // Handle selection of a search result
  const handleSelectResult = (symbol) => {
    setSearchQuery(symbol);
    setSearchResults([]);
    setFilteredResults([]);
    navigate(`/symbol/${symbol}`);
  };

  // Function to handle logout
  const handleLogout = () => {
    // Clear tokens from localStorage and sessionStorage
    localStorage.removeItem('token');
    sessionStorage.clear();

    // Clear the token cookie
    document.cookie = "access_token_cookie=; Max-Age=0; path=/; domain=juicd.io; Secure; HttpOnly;";

    // Redirect to the login page
    navigate('/auth/sign-in');
  };

  const sourceMapping = {
    all: "All", // Default filter for "Show All"
    asx_shorts: "Shorts",
    eodhd_data: "EOD Data",
    citi_pdf_stock: "Stocks",
    citi_pdf_international_index: "Indices",
    citi_pdf_commodities: "Commodities",
  };

  // List of available filters
  const filters = Object.keys(sourceMapping);

  return (
      <Flex
          w={{ sm: '100%', md: 'auto' }}
          alignItems="center"
          flexDirection="row"
          bg={menuBg}
          flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
          p="10px"
          borderRadius="30px"
          boxShadow={shadow}
          position="relative" // Make this parent relative
      >
        <SearchBar
            value={searchQuery}
            onChange={(e) => handleInputChange(e.target.value)}
            placeholder="Search symbols..." // Keep the design from your project
            me="10px"
            borderRadius="30px"
        />
        {/* Filter Buttons */}
        <Flex mt="10px" gap="5px" flexWrap="wrap">
          {filters.map((filterKey) => (
              <Button
                  key={filterKey}
                  onClick={() => handleFilterChange(filterKey)}
                  variant={selectedFilter === filterKey ? "solid" : "outline"}
                  colorScheme={selectedFilter === filterKey ? "blue" : "gray"}
                  size="sm"
                  borderRadius="20px"
              >
                {sourceMapping[filterKey]}
              </Button>
          ))}
        </Flex>
        {/* Dropdown for search results */}
        {searchQuery && (
            <Box
                ref={dropdownRef} // Attach the ref here
                position="absolute"
                top="calc(100% + 4px)" // Dropdown appears directly below the search bar with a small gap
                left="0"
                bg={dropdownBg}
                borderRadius="10px"
                boxShadow="lg"
                maxWidth="400px"
                zIndex="10"
                mt="4px" // Small margin between the search bar and dropdown
                border={`1px solid ${borderColor}`}
                maxHeight="400px" // Limit the height of the dropdown
                overflowY="auto"  // Enable vertical scrolling
            >
              {isLoading && currentPage === 1? (
                  <Flex justifyContent="center" alignItems="center" p="10px">
                    <Spinner size="sm" />
                  </Flex>
              ) : filteredResults.length > 0 ? (
                  filteredResults.map((result, index) => (
                      <Box
                          key={index}
                          p="10px"
                          cursor="pointer"
                          _hover={{ bg: "gray.100" }}
                          onClick={() => handleSelectResult(result.symbol)}
                      >
                        <Text fontSize="sm" fontWeight="bold" color={textColor}>
                          {result.symbol}
                        </Text>
                        <Text fontSize="xs" color="gray.500">
                          {result.name} - {result.exchange} ({sourceMapping[result.source]})
                        </Text>
                      </Box>
                  ))
              ) : (
                  <Text p="10px" fontSize="sm" color="gray.500">
                    No results found.
                  </Text>
              )}
              {isLoading && currentPage > 1 && (
                  <Flex justifyContent="center" alignItems="center" p="10px">
                    <Spinner size="sm" />
                  </Flex>
              )}
            </Box>
        )}
        <Button
            variant="no-hover"
            bg="transparent"
            p="0px"
            minW="unset"
            minH="unset"
            h="18px"
            w="max-content"
            onClick={toggleColorMode}
        >
          <Icon
              me="10px"
              h="18px"
              w="18px"
              color={navbarIcon}
              as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
          />
        </Button>
        <Menu>
          <MenuButton p="0px">
            <Avatar
                _hover={{ cursor: 'pointer' }}
                color="white"
                name="John Doe"
                bg="#11047A"
                size="sm"
                w="40px"
                h="40px"
            />
          </MenuButton>
          <MenuList
              boxShadow={shadow}
              p="0px"
              mt="10px"
              borderRadius="20px"
              bg={menuBg}
              border="none"
          >
            <Flex w="100%" mb="0px">
              <Text
                  ps="20px"
                  pt="16px"
                  pb="10px"
                  w="100%"
                  borderBottom="1px solid"
                  borderColor={borderColor}
                  fontSize="sm"
                  fontWeight="700"
                  color={textColor}
              >
                👋&nbsp; Hey, User
              </Text>
            </Flex>
            <Flex flexDirection="column" p="10px">
              <MenuItem
                  _hover={{ bg: 'none' }}
                  _focus={{ bg: 'none' }}
                  color="red.400"
                  borderRadius="8px"
                  px="14px"
                  onClick={handleLogout} // Call logout function here
              >
                <Text fontSize="sm">Log out</Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
