import React from 'react';
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "./utils/auth";
import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import SymbolDashboard from 'views/admin/symboldata';
import NFTMarketplace from 'views/admin/marketplace';
import Profile from 'views/admin/profile';
import DataTables from 'views/admin/dataTables';
import RTL from 'views/admin/rtl';

// Auth Imports
import SignInCentered from 'views/auth/signIn';

// Protected Route Component
const ProtectedRoute = ({ component  }) => {
  // console.log("ProtectedRoute called");
  // console.log("Authenticated:", isAuthenticated());
  // console.log("Component received:", component);
  return isAuthenticated() ? component  : <Navigate to="/auth/sign-in" replace />;
};


const protect = (component) => <ProtectedRoute component={component} />;
const routes = [
  {
    name: 'Main Dashboard',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: protect(<MainDashboard />),
    showInNav: true, // Include this in the navigation bar
  },
  {
    name: 'Data Playground',
    layout: '/admin',
    path: "/symboldata",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: protect(<SymbolDashboard />),
    showInNav: true, // Include this in the navigation bar
  },
  // {
  //   name: 'NFT Marketplace',
  //   layout: '/admin',
  //   path: '/nft-marketplace',
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  // {
  //   name: 'Data Tables',
  //   layout: '/admin',
  //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //   path: '/data-tables',
  //   component: <DataTables />,
  // },
  // {
  //   name: 'Profile',
  //   layout: '/admin',
  //   path: '/profile',
  //   icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   component: <Profile />,
  // },
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <SignInCentered />,
    showInNav: false, // Include this in the navigation bar
  },
  // {
  //   name: 'RTL Admin',
  //   layout: '/rtl',
  //   path: '/rtl-default',
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   component: <RTL />,
  // },
];

export default routes;
