/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  GridItem,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react"; //new added
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
// import CheckTable from "views/admin/default/components/CheckTable";
import ASXTable from "views/admin/default/components/ASXTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";

import CitiPDFStockTable from "views/admin/default/components/CitiPDFStockTable";
import CitiPDFAUIndexTable from "views/admin/default/components/CitiPDFAustralianIndexTable";
import CitiPDFInternationalIndexTable from "views/admin/default/components/CitiPDFInternationalIndexTable";
import CitiPDFCurrencyTable from "views/admin/default/components/CitiPDFCurrencyTable";
import CitiPDFCommoditiesTable from "views/admin/default/components/CitiPDFCommoditiesTable";
import ETFReportTable from "views/admin/default/components/etfReportTable";

import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";


export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  // console.log("MainDashboard mounted");
  // State to hold dynamic table data - NEW CODE
  const [tableData, setTableData] = useState([]); // Holds fetched data for the table
  const [columnsData, setColumnsData] = useState([]); // Stores column definitions for the table
  const [loading, setLoading] = useState(true); // Tracks loading state
  const [currentPage, setCurrentPage] = useState(1); // Tracks current page
  const [perPage, setPerPage] = useState(10); // Tracks rows per page
  const [totalRecords, setTotalRecords] = useState(0); // Tracks total records

  // **NEW FUNCTION: Generate dynamic columns**
  const generateColumns = (data) => {
    const columnMappings = {
      asx_code: "ASX Code",
      company_name: "Company Name",
      industry: "Industry",
      product_class: "Product Class",
      gross_short_sold_volume: "Gross Short Volume",
      issued_capital: "Issued Capital",
      percent_issued_capital: "Percent Issued Capital",
      data_date: "Date",
    };

    // Generate column definitions based on keys in the data
    return Object.keys(data[0]).map((key) => ({
      Header: columnMappings[key] || key, // Use mapped name or key as header
      accessor: key, // Set accessor for the column
    }));
  };


  // Function to fetch data from the API with pagination
  const fetchData = async (page, rowsPerPage) => {
    setLoading(true);
    try {
      const response = await fetch(
          `https://juicd.io/api/shorted-stocks?page=${page}&per_page=${rowsPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Use token from localStorage
            },
          }
      );

      if (response.ok) {
        const result = await response.json(); // Parse JSON response
        // console.log("API Data:", result.data); // Debug log
        if (Array.isArray(result.data) && result.data.length > 0) {
          setColumnsData(generateColumns(result.data)); // Generate column definitions
          setTableData(result.data); // Populate table data
          setTotalRecords(result.total_records); // Total records from API
        } else {
          console.error("Invalid or empty data received");
          setTableData([]);
        }
      } else {
        console.error("Error fetching data:", response.statusText);
      }
    } catch (error) {
      console.error("Error during fetch:", error);
    } finally {
      setLoading(false);
    }
  };

  // Trigger data fetch on component mount and page/perPage changes
  useEffect(() => {
    fetchData(currentPage, perPage);
  }, [currentPage, perPage]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Earnings'
          value='$350.4'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Spend this month'
          value='$642.39'
        />
        <MiniStatistics growth='+23%' name='Sales' value='$574.34' />
        <MiniStatistics
          endContent={
            <Flex me='-16px' mt='10px'>
              <FormLabel htmlFor='balance'>
                <Avatar src={Usa} />
              </FormLabel>
              <Select
                id='balance'
                variant='mini'
                mt='5px'
                me='0px'
                defaultValue='usd'>
                <option value='usd'>USD</option>
                <option value='eur'>EUR</option>
                <option value='gba'>GBA</option>
              </Select>
            </Flex>
          }
          name='Your balance'
          value='$1,000'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
            />
          }
          name='New Tasks'
          value='154'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name='Total Projects'
          value='2935'
        />
      </SimpleGrid> */}

      {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <TotalSpent />
        <WeeklyRevenue />
      </SimpleGrid> */}
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <GridItem colSpan={1}>
          {/*<Box boxShadow="base" bg={boxBg} p="20px">*/}
        <ETFReportTable/>
          {/*</Box>*/}
        </GridItem>
        {/* Second Column: Two Tables Stacked */}
        <GridItem colSpan={1}>
          {/*<Box boxShadow="base" bg={boxBg} p="20px" mb="20px">*/}
          <Box p = "1px">
        {loading ? (
            <p>Loading...</p> // Show loading message while data is being fetched - NEW CODE
        ) : (
            <ASXTable/>
        )}
            </Box>
          {/*</Box>*/}
          <Box p = "1px">
            <CitiPDFStockTable />
          </Box>
          </GridItem>
      </SimpleGrid>
        {/*<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>*/}
        {/*  <CitiPDFAUIndexTable/>*/}
        {/*</SimpleGrid>*/}
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
          <CitiPDFInternationalIndexTable/>
          <CitiPDFCommoditiesTable/>
        </SimpleGrid>
        {/*<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>*/}
        {/*  <CitiPDFCurrencyTable/>*/}
        {/*</SimpleGrid>*/}
        {/*<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>*/}
        {/*  <CitiPDFCommoditiesTable/>*/}
        {/*</SimpleGrid>*/}
        {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <DailyTraffic />
          <PieCard />
        </SimpleGrid> */}
      {/*</SimpleGrid>*/}
      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
}
