/* eslint-disable */

import {
    Flex,
    Box,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Select,
    Button,
    Tooltip,
    Icon,
    Spinner,
} from "@chakra-ui/react";
import React from "react";
import { flexRender, createColumnHelper, useReactTable, getCoreRowModel } from "@tanstack/react-table";
import * as XLSX from "xlsx";
import ReactDatePicker from "react-datepicker"; // NEW: Import React Datepicker
import "react-datepicker/dist/react-datepicker.css"; // NEW: Import Datepicker CSS
import Card from "components/card/Card";
import { FaDownload } from "react-icons/fa";

const columnHelper = createColumnHelper();

export default function ETFReportTable() {
    const [tableData, setTableData] = React.useState([]);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [totalPages, setTotalPages] = React.useState(1);
    const [perPage, setPerPage] = React.useState(50);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [loading, setLoading] = React.useState(false);

    // NEW: State to manage the selected date for fetching data
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [availableDates, setAvailableDates] = React.useState([]);
    // States to hold the dates for the headers
    const [prevCostDate, setPrevCostDate] = React.useState("");
    const [latestCostDate, setLatestCostDate] = React.useState("");

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    // Utility function to format dates in "YYYY-MM-DD" format
    const formatDate = (dateString) => {
        if (!dateString) return "N/A"; // Handle empty or invalid dates
        const date = new Date(dateString); // Convert string to Date object
        return date.toISOString().split("T")[0]; // Format as "YYYY-MM-DD"
    };


    // Utility function to calculate a color scale
    const getColorForValue = (value) => {
        // console.log("Value received for color:", value); // Debugging line

        // Ensure the value is a valid number by removing the '%' and parsing
        const numericValue = parseFloat(value?.toString().replace('%', ''));

        if (isNaN(numericValue)) return "transparent"; // If value cannot be parsed, return transparent

        const intensity = Math.min(1, Math.abs(numericValue) / 10); // Scale the intensity (capped at 100%)
        if (numericValue < 0) {
            return `rgba(255, 0, 0, ${intensity})`; // Shades of red for negative values
        } else {
            return `rgba(0, 255, 0, ${intensity})`; // Shades of green for positive values
        }
    };


    // Fetch Table Data
    const fetchTableData = async (page, limit) => {
        try {
            setLoading(true);
            const response = await fetch(
                `https://juicd.io/api/etf-report?page=${page}&per_page=${limit}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            if (response.ok) {
                const data = await response.json();


                setTableData(data.data || []);
                setTotalRecords(data.total_records || 0);
                setTotalPages(data.total_pages || 1);
            } else {
                console.error("Failed to fetch data");
            }
        } catch (error) {
            console.error("Error fetching table data:", error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        fetchTableData(currentPage, perPage); // MODIFIED: Include `selectedDate` in the fetch call
    }, [currentPage, perPage]); // MODIFIED: Add `selectedDate` as a dependency

    // Handle Change in Entries Per Page
    const handlePerPageChange = (event) => {
        const newPerPage = Number(event.target.value);
        setPerPage(newPerPage);
        setCurrentPage(1);
        fetchTableData(1, newPerPage); // MODIFIED: Pass `selectedDate` to fetch data
    };

    // Handle Export to Excel
    const handleExport = () => {
        if (tableData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(tableData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "ETF Report");
            XLSX.writeFile(wb, `ETF_Report_${new Date().toISOString().slice(0, 10)}.xlsx`);
        } else {
            console.error("No data available to export.");
        }
    };

    // Define Table Columns
    const columns = [
        columnHelper.accessor("market", {
            header: "Market",
            cell: (info) => (
                <Text color={textColor} fontSize="xs" fontWeight="normal">
                    {info.getValue() || "N/A"}
                </Text>
            ),
        }),
        columnHelper.accessor("country", {
            header: "Country",
            cell: (info) => (
                <Text color={textColor} fontSize="xs" fontWeight="normal">
                    {info.getValue() || "N/A"}
                </Text>
            ),
        }),
        columnHelper.accessor("region", {
            header: "Region",
            cell: (info) => (
                <Text color={textColor} fontSize="xs" fontWeight="normal">
                    {info.getValue() || "N/A"}
                </Text>
            ),
        }),
        columnHelper.accessor("sma_10", {
            header: "% Above SMA10",
            cell: (info) => (
                <Text color={textColor} fontSize="xs" fontWeight="normal" backgroundColor={getColorForValue(info.getValue())}>
                    {info.getValue() || "N/A"}
                </Text>
            ),
        }),
        columnHelper.accessor("sma_20", {
            header: "% Above SMA20", // Show "Prev Cost" with date in the header
            cell: (info) => (
                <Text color={textColor} fontSize="xs" fontWeight="normal" backgroundColor={getColorForValue(info.getValue())}>
                    {info.getValue() || "N/A"}
                </Text>
            ),
        }),
        columnHelper.accessor("sma_50", {
            header: "% Above SMA50", // Show "Latest Cost" with date in the header
            cell: (info) => (
                <Text color={textColor} fontSize="xs" fontWeight="normal" backgroundColor={getColorForValue(info.getValue())}>
                    {info.getValue() || "N/A"}
                </Text>
            ),
        }),
        columnHelper.accessor("sma_100", {
            header: "% Above SMA100",
            cell: (info) => (
                <Text color={textColor} fontSize="xs" fontWeight="normal" backgroundColor={getColorForValue(info.getValue())}>
                    {info.getValue() !== null ? `${info.getValue()}` : "N/A"}
                </Text>
            ),
        }),
        columnHelper.accessor("sma_200", {
            header: "% Above SMA200",
            cell: (info) => (
                <Text color={textColor} fontSize="xs" fontWeight="normal" backgroundColor={getColorForValue(info.getValue())}>
                    {info.getValue() !== null ? `${info.getValue()}` : "N/A"}
                </Text>
            ),
        }),
    ];

    const table = useReactTable({
        data: tableData,
        columns,
        state: { sorting: [] },
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <Card flexDirection="column" w="100%" px="0px" overflowX="auto">
            <Flex px="30px" mb="8px" justifyContent="space-between" align="center">
                <Text color={textColor} fontSize="22px" fontWeight="700">
                    Market Moving Report
                </Text>
                <Flex alignItems="center" gap="10px">
                    {/*<Text fontSize="sm">Show:</Text>*/}
                    {/*<Select w="100px" size="sm" value={perPage} onChange={handlePerPageChange}>*/}
                    {/*    /!*<option value={5}>5</option>*!/*/}
                    {/*    /!*<option value={10}>10</option>*!/*/}
                    {/*    /!*<option value={20}>20</option>*!/*/}
                    {/*    <option value={50}>50</option>*/}
                    {/*    <option value={100}>100</option>*/}
                    {/*</Select>*/}
                    <Tooltip label="Export to Excel" fontSize="md" placement="top">
                        <Button
                            onClick={handleExport}
                            leftIcon={<Icon as={FaDownload} />}
                            colorScheme="teal"
                            size="sm"
                        >
                            Export
                        </Button>
                    </Tooltip>
                </Flex>
            </Flex>
            <Box>
                {loading ? (
                    <Flex justify="center" align="center" py="20">
                        <Spinner size="xl" color="teal.500" />
                    </Flex>
                ) : (
                    <>
                        <Table variant="striped" color="gray.700" size="sm" style={{ tableLayout: "fixed" }}>
                            <Thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <Tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => (
                                            <Th key={header.id} textAlign="center" align="center">
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </Th>
                                        ))}
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody>
                                {table.getRowModel().rows.map((row) => (
                                    <Tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <Td
                                                key={cell.id}
                                                textAlign="center"
                                                align="center"
                                                py={1}
                                                px={2}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </Td>
                                        ))}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                        {/*<Flex justify="space-between" align="center" px="25px" py="10px">*/}
                        {/*    <Text>Page {currentPage} of {totalPages}</Text>*/}
                        {/*    <Flex gap="10px">*/}
                        {/*        <Button*/}
                        {/*            size="sm"*/}
                        {/*            onClick={() => setCurrentPage(currentPage - 1)}*/}
                        {/*            disabled={currentPage === 1}*/}
                        {/*        >*/}
                        {/*            Previous*/}
                        {/*        </Button>*/}
                        {/*        <Button*/}
                        {/*            size="sm"*/}
                        {/*            onClick={() => setCurrentPage(currentPage + 1)}*/}
                        {/*            disabled={currentPage === totalPages}*/}
                        {/*        >*/}
                        {/*            Next*/}
                        {/*        </Button>*/}
                        {/*    </Flex>*/}
                        {/*</Flex>*/}
                    </>
                )}
            </Box>
        </Card>
    );
}
